import React, { FunctionComponent } from "react";
import { Article } from "@src/hooks/useArticles";
import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const ArticlesWrapper = styled.article`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;

	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
	height: 100%;

	visibility: hidden;
	opacity: 0;

	transition: opacity 200ms ease-in-out;

	&.enter {
		visibility: visible;
	}
	&.enter-active {
		visibility: visible;
		opacity: 1;
	}
	&.enter-done {
		visibility: visible;
		opacity: 1;
	}
`;

const ArticleRow = styled.ul`
	display: flex;
	max-height: 18vh;
	height: 100%;

	@media ${(props) => props.theme.breakpoints[1]} {
		padding-right: 4vh;
	}
`;

const ArticleItem = styled.li`
	margin-left: 4px;
	padding-top: 5px;
	padding-bottom: 5px;
	height: 100%;

	a {
		height: 100%;
		position: relative;
		display: block;
		font-family: ${(props) => props.theme.fonts.heading};
	}

	img {
		display: block;
		max-width: 24vw;
		max-height: 100%;
	}

	a:hover span {
		opacity: 1;
	}
	span {
		position: absolute;
		top: 0;
		left: 0;

		opacity: 0;

		display: flex;
		align-items: center;
		justify-content: center;
		border: 6px solid #fd00b8;
		width: 100%;
		height: 100%;

		color: #fd00b8;
		word-break: break-word;
		font-size: 87.5%;
		text-transform: uppercase;
		text-align: center;

		background-color: rgba(255, 255, 255, 0.4);
		transition: opacity 300ms ease-out;

		${(props) => props.theme.breakpoints[1]} {
			font-size: 100%;
		}
	}
`;

interface OwnProps {
	article: Article;
	active: boolean;
}

type Props = OwnProps;

const HomeArticles: FunctionComponent<Props> = ({
	article,
	active,
	...props
}): JSX.Element => (
	<CSSTransition in={active} timeout={200}>
		<ArticlesWrapper data-jump={true} {...props}>
			{Object.values(article).map((row, i) => (
				<ArticleRow key={i}>
					{row.map((a) => (
						<ArticleItem key={a.name}>
							<OutboundLink
								href={`https://shop.sample-cm.com/products/${a.id}`}
								target={"_blank"}
							>
								<img src={a.src} alt={a.name} />
								<span>{a.name}</span>
							</OutboundLink>
						</ArticleItem>
					))}
				</ArticleRow>
			))}
		</ArticlesWrapper>
	</CSSTransition>
);

export default HomeArticles;
