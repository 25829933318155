import React, {
	useContext,
	FunctionComponent,
	useMemo,
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
} from "react";
import { PageProps, withPrefix } from "gatsby";
import { useTheme } from "@emotion/react";
import {
	LoadingContext,
	MediaContext,
	// usePreloads,
} from "@gx";
import { useVideoArtDirection } from "@gx-ui";

// Components
import useJump from "@src/hooks/useJump";
import HomePlayer from "@src/elements/HomePlayer";
import HomeWrapper from "@src/elements/HomeWrapper";
import HomeMenu from "@src/elements/HomeMenu";
import useArticles from "@src/hooks/useArticles";

interface OwnProps {}

type Props = OwnProps & PageProps<any>;

const HomePage: FunctionComponent<Props & PageProps> = ({
	location,
}): JSX.Element => {
	const theme = useTheme();
	const { loaded, loading } = useContext(LoadingContext);
	const { muted } = useContext(MediaContext);
	const redirect = useRef(setTimeout(() => {}));
	const player = useRef<HTMLVideoElement>(null);

	// Player source
	const medias = useMemo(
		() => [
			{
				mp4: withPrefix("/videos/mp4/2160/v3.mp4"),
				webm: withPrefix("/videos/webm/2160/v3.webm"),
				poster: withPrefix("/videos/poster/2160/v3.jpg"),
				mediaQuery: theme.breakpoints[3],
			},
			{
				mp4: withPrefix("/videos/mp4/1440/v3.mp4"),
				webm: withPrefix("/videos/webm/1440/v3.webm"),
				poster: withPrefix("/videos/poster/1440/v3.jpg"),
				mediaQuery: theme.breakpoints[2],
			},
			{
				mp4: withPrefix("/videos/mp4/1080/v3.mp4"),
				webm: withPrefix("/videos/webm/1080/v3.webm"),
				poster: withPrefix("/videos/poster/1080/v3.jpg"),
				mediaQuery: theme.breakpoints[1],
			},
			{
				mp4: withPrefix("/videos/mp4/720/v3.mp4"),
				webm: withPrefix("/videos/webm/720/v3.webm"),
				poster: withPrefix("/videos/poster/720/v3.jpg"),
				mediaQuery: theme.breakpoints[0],
			},
		],
		[theme]
	);
	const source = useVideoArtDirection(medias);

	// Player
	const [time, setTime] = useState<number>(0);
	const [playing, setPlaying] = useState(false);

	// Articles
	const [isArticle, setIsArticle] = useState(false);
	const article = useArticles(time);

	// About
	const [isAbout, setIsAbout] = useState(false);
	useEffect(() => {
		setIsAbout(location.pathname == withPrefix("/about/"));
	}, [location.pathname, setIsAbout]);
	useEffect(() => {
		if (loaded) {
			if (!isAbout) {
				redirect.current = setTimeout(
					() => setPlaying(true),
					theme.animations.page.enter - 400
				);
			} else {
				clearTimeout(redirect.current);
				setIsArticle(false);
				setPlaying(false);
			}
		}
	}, [loaded, isAbout, setPlaying, setIsArticle]);

	// Action on Jump press/release
	const buttonRef = useJump({
		onPress: () => {
			clearTimeout(redirect.current);
			setIsArticle(true);
			setPlaying(false);
		},
		onRelease: () => {
			clearTimeout(redirect.current);
			setIsArticle(false);
			setPlaying(true);
		},
	});

	// Preloads images
	/*usePreloads(
		medias.map((m) => ({ media: m.mediaQuery, as: "image", href: m.poster }))
	);*/

	// Loading
	useLayoutEffect(() => {
		if (loading && player.current) {
			player.current.src = source.mp4;
			player.current.poster = source.poster;
		}
	}, [loading]);

	return (
		<HomeWrapper>
			<HomePlayer
				ref={player}
				playing={playing}
				playsInline={true}
				timeUpdate={(t) => setTime(t)}
				loop={true}
				muted={muted}
			/>
			<HomeMenu
				time={time}
				buttonRef={buttonRef}
				article={article}
				active={isArticle}
			/>
		</HomeWrapper>
	);
};

export default HomePage;
