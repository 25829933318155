import styled from "@emotion/styled";
import React, {
	ForwardRefRenderFunction,
	forwardRef,
	PropsWithChildren,
} from "react";

const ButtonWrapper = styled.button`
	position: relative;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	border: none;
	padding: 0 1.45em 5px 0;
	width: 24vw;

	color: black;
	background: none;
	cursor: pointer;

	font-size: 112.5%;
	line-height: 1em;
	text-align: left;

	@media ${(props) => props.theme.breakpoints[1]} {
		width: 20vh;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		left: -0.9em;
		transform: skewX(-45deg);

		width: 100%;
		height: 1.1em;
		background: #e51ab1;
	}
`;

const ButtonJump = styled.span`
	position: relative;
	padding-left: 2px;
	padding-top: 2px;
	font-family: ${(props) => props.theme.fonts.heading};
	white-space: nowrap;
`;

const ButtonTime = styled.span`
	background: white;
	padding: 2px 2px 4px 2px;
`;

interface OwnProps {
	time: number;
}

type Props = OwnProps;

const HomeJumpButton: ForwardRefRenderFunction<
	HTMLButtonElement,
	PropsWithChildren<Props>
> = ({ time, children, ...rest }, ref) => {
	const seconds = Math.floor(time % 60);
	const minutes = Math.floor(time / 60);

	return (
		<ButtonWrapper {...rest} ref={ref}>
			<ButtonJump data-jump={true}>{children}</ButtonJump>
			<ButtonTime data-jump={true}>
				{("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}
			</ButtonTime>
		</ButtonWrapper>
	);
};

export default forwardRef(HomeJumpButton);
